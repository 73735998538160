import React, {useEffect, useState} from 'react';
import { Paper, FormControlLabel, RadioGroup, Radio, Button, Typography, Divider, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { postVerificaitons, putComparingData } from '../lib/api/ekyc';
import Loading from "../components/Loading";
import { getProfile, updateProfileSupportingdocuments } from '../lib/api/profile';
import ImageButton from '../components/ImageButton';

export const Ekyc = () =>{
  const [selectedRadioBtnValue, setSelectedRadioBtnValue] = useState("ekyc1");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEkyc, setSelectedEkyc] = useState('ekyc1');
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const initial = query.get('initial');

  const handleGetProfile = async() =>{
    try{
      const res = await getProfile();
      if(res.status === 200){
        const rec = res.data.record;
        const identification = res.data.record.identification;
        const recentIdentification = res.data.record.recentIdentification?.tdStateC;
        const user = res.data.record;
        // 連続否認の場合もdisable
        const thirdFlg = res.data.record.threeconsecutivedenials;
        if(thirdFlg || identification == '本人確認済み' || identification == '確認中' || identification == '書類提出済み')
        {
          navigate('/profile/top');
        }
        if((identification == null || identification == '-' || identification == '否認') && (recentIdentification == 'document_submitted' || recentIdentification == 'in_progress')){
          navigate('/profile');
        }
        if(!user.raPostalcode || !user.raState || !user.raCity || !user.raStreet){
          navigate('/profile/top');
        }
      }
    }catch(e){
      console.log(e);
      navigate('/profile/top');
    }
  }

  useEffect(() =>{
    handleGetProfile();
  }, []);

  const trustdockModal = (public_id, plan_ids, update_type, is_development) =>{
    const trustdock = new Trustdock({
      publicId: public_id,
      plans: plan_ids,
      openerSelector: '.opener'
    });
    const autoOpen = () => {
      if (trustdock.isLoaded) {
        setIsLoading(false);
        trustdock.open()
      } else {
        trustdock.on('loaded', () => {
          setIsLoading(false);
          trustdock.open()
        })
      }
    }
    const documentsubmittedAction = async() => {
      const res = await putComparingData(public_id, is_development, update_type == 1 || update_type == 2);
      if (update_type <= 1){
        await updateProfileSupportingdocuments('不要');
      }else{
        await updateProfileSupportingdocuments('未確認');
      }
    }
    const completedAction = () => {
      if(update_type == 2){
        if(initial){
          navigate('/personalidentification?initial=true');
        }else{
          navigate('/personalidentification');
        }
      }else{
        if(initial){
          navigate('/apply/factoring_new');
        }else{
          navigate('/profile/top');
        }
      }
    }
    const canceledAction = () => {
    }
    //// イベント受け取った場合の処理
    // 身元確認書類の提出が完了した場合の処理を記述
    // 例えば、提出後モーダルを閉じないユーザーに対して強制的に次のページへ遷移する等
    trustdock.on('documentsubmitted', documentsubmittedAction)
    // 身元確認書類の提出が完了しモーダルを閉じた場合の処理を記述
    trustdock.on('completed',completedAction )
    // 身元確認書類の提出をせずモーダルを閉じた場合の処理を記述
    trustdock.on('canceled', canceledAction)
    autoOpen()
  }

  useEffect(() =>{
    const scripts = document.getElementsByTagName('script');
    for(let script of scripts){
      if(script.src == 'https://helper.trustdock.io/v2/verification_helper.js') script.remove();
      if(script.src == 'https://helper.test.trustdock.io/v2/verification_helper.js') script.remove();
    }
    if(location.host == 'www.factoru.chushokigyo-support.or.jp'){
      const head = document.getElementsByTagName('head')[0] as HTMLElement;
      const scriptUrl = document.createElement('script');
      scriptUrl.type = 'text/javascript';
      scriptUrl.src = 'https://helper.trustdock.io/v2/verification_helper.js';
      head.appendChild(scriptUrl);
    }else{
      const head = document.getElementsByTagName('head')[0] as HTMLElement;
      const scriptUrl = document.createElement('script');
      scriptUrl.type = 'text/javascript';
      scriptUrl.src = 'https://helper.test.trustdock.io/v2/verification_helper.js';
      head.appendChild(scriptUrl);
    }
  }, [selectedRadioBtnValue])

  const onclickSubmit = async () =>{
    if(isLoading) return;
    setIsLoading(true);
    const is_development = selectedRadioBtnValue == 'ekyc1' || selectedRadioBtnValue == 'ekyc2' || selectedRadioBtnValue == 'ekyc3' ? false : true;
    const resp = await postVerificaitons(is_development);
    if(resp.status == 202){
      if(selectedRadioBtnValue == 'ekyc1'){
        if(location.host == 'www.factoru.chushokigyo-support.or.jp'){
          trustdockModal(resp.data.data.publicId, [{id: 'ca13437b-39eb-4fc9-add9-3058513c361b'}, {id: '2c7f0ca1-06c5-4cbc-ac6f-960c76703a56', document_source_plan_id: 'ca13437b-39eb-4fc9-add9-3058513c361b'}], 0, is_development);
        }else{
          trustdockModal(resp.data.data.publicId, [{id: '3d829e67-63ff-4a50-bc14-422b08ff3d08'}, {id: 'a2563ba3-ba5f-4c09-a8d8-d821e8fb2224', document_source_plan_id: '3d829e67-63ff-4a50-bc14-422b08ff3d08'}], 0, is_development);
        }
      }else if(selectedRadioBtnValue == 'ekyc2'){
        if(location.host == 'www.factoru.chushokigyo-support.or.jp'){
          trustdockModal(resp.data.data.publicId, [{id: '5ee2135c-fbcc-454a-af0d-8da0ce9f8089'}, {id: '2c7f0ca1-06c5-4cbc-ac6f-960c76703a56', document_source_plan_id: '5ee2135c-fbcc-454a-af0d-8da0ce9f8089'}, {id: '9dc59852-ef9f-49dc-a208-35bbf01204d3'}], 1, is_development);
        }else{
          trustdockModal(resp.data.data.publicId, [{id: '7244a24a-2bc6-4c77-836b-37e346ea7b89'}, {id: 'a2563ba3-ba5f-4c09-a8d8-d821e8fb2224', document_source_plan_id: '7244a24a-2bc6-4c77-836b-37e346ea7b89'}, {id: '8ea98ec3-805f-4302-9a03-9c4d8382da1f'}], 1, is_development);
        }
      }else if(selectedRadioBtnValue == 'ekyc3'){
        if(location.host == 'www.factoru.chushokigyo-support.or.jp'){
          trustdockModal(resp.data.data.publicId, [{id: '9ac56d41-e70e-4d47-ae6f-6391611f192a'}, {id: '2c7f0ca1-06c5-4cbc-ac6f-960c76703a56', document_source_plan_id: '9ac56d41-e70e-4d47-ae6f-6391611f192a'}, {id: '9dc59852-ef9f-49dc-a208-35bbf01204d3'}], 2, is_development);
        }else{
          trustdockModal(resp.data.data.publicId, [{id: '34c713d3-ca5f-4e74-aaf8-41cb5d6532f8'}, {id: 'a2563ba3-ba5f-4c09-a8d8-d821e8fb2224', document_source_plan_id: '34c713d3-ca5f-4e74-aaf8-41cb5d6532f8'}, {id: '8ea98ec3-805f-4302-9a03-9c4d8382da1f'}], 2, is_development);
        }
      }
    }
  }

  const onRadioButtonChange = (evt)=>{
    setSelectedRadioBtnValue(evt.target.value);
  }
  return (
    <Box sx={{ width: '100%', borderRadius: 2, pl: {xs: 2}, pr: {xs: 2}, pt: {xs: 1, md: '5%'}, pb: {xs: 1}, height: {md: '80%'} }}>
      <Typography>本人確認書類を選んでください。</Typography>
      <Box sx={{display: {md: 'flex'}, justifyContent: {md: 'center'}, alignItems: {md: 'center'}, pt: {md: '5%'}}}>
        <ImageButton onClick={(e) => {setSelectedRadioBtnValue('ekyc1')}} imgUrl="/icons/myno.svg" buttonTitle="運転免許証、マイナンバーなど" disabled={selectedRadioBtnValue=="ekyc1"} disableColor='#FFFFFF' disableBorder='2px solid #EBBF6C' isRadio={true} arrowEnable={false}/>
        <Divider orientation="vertical" variant="middle" flexItem sx={{display: {md: 'block', xs: 'none'}, margin: 'auto', height: '220px'}}/>
        <Divider orientation="horizontal" variant="middle" flexItem sx={{display: {md: 'none', xs: 'block'}, margin: 'auto', width: '80%'}}/>
        <ImageButton onClick={(e) => {setSelectedRadioBtnValue('ekyc2')}} imgUrl="/icons/passport.svg" buttonTitle="パスポート" disabled={selectedRadioBtnValue=="ekyc2"} disableColor='#FFFFFF' disableBorder='2px solid #EBBF6C' isRadio={true} arrowEnable={false}/>
        <Divider orientation="vertical" variant="middle" flexItem sx={{display: {md: 'block', xs: 'none'}, margin: 'auto', height: '220px'}}/>
        <Divider orientation="horizontal" variant="middle" flexItem sx={{display: {md: 'none', xs: 'block'}, margin: 'auto', width: '80%'}}/>
        <ImageButton onClick={(e) => {setSelectedRadioBtnValue('ekyc3')}} imgUrl="/icons/hoken.svg" buttonTitle="健康保険証"  disabled={selectedRadioBtnValue=="ekyc3"} disableColor='#FFFFFF' disableBorder='2px solid #EBBF6C' isRadio={true} arrowEnable={false}/>
      </Box>
      <Box sx={{mt: 3}}>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>{onclickSubmit()}}
          sx={{borderRadius: 5, width: {md: '20%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
        >
          本人確認を行う
        </Button>
      </Box>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
    </Box>
  );
};